<template>
  <div class="flex items-center flex-col pt-13 pb-7 relative">
    <p class="auth-error" v-if="error && error.length">{{ error }}</p>
    <template v-if="!isFormSend">
      <h1 class="auth-form__title"><b>Create</b> new password</h1>

      <Form @submit="onSubmit" :validation-schema="schema" class="auth-form">
        <ui-validation-input
          name="password"
          type="password"
          label="Password"
          class="auth-form__field"
        />
        <ui-validation-input
          name="confirm_password"
          type="password"
          label="Confirm password"
          class="auth-form__field"
        />
        <ui-button rounded class="w-auth-form" :button-type="ButtonType.Blue"
          >Next</ui-button
        >
      </Form>

      <div
        class="w-auth-form"
        v-if="
          route.name !== ROUTER_NAMES.CREATE_PASSWORD &&
          route.name !== ROUTER_NAMES.RESET_PASSWORD
        "
      >
        <div class="flex items-center justify-around w-full pt-3.5 pb-2.5">
          <div class="h-px bg-gray-300 w-full"></div>
          <span class="px-9 text-sm">Or</span>
          <div class="h-px bg-gray-300 w-full"></div>
        </div>

        <social-auth-buttons />
      </div>
    </template>

    <template v-if="isFormSend">
      <img src="@/assets/images/icons/check_circle.svg" alt="check_circle" />
      <h1 class="auth-form__title mt-6 font-bold">Complete</h1>

      <ui-button
        @click="
          $router.push({
            name: ROUTER_NAMES.SIGN_IN,
            query: { from: route.query.from },
          })
        "
        rounded
        :button-type="ButtonType.Blue"
        class="mb-28 w-auth-form"
        >Sign in</ui-button
      >
    </template>

    <p class="text-center text-gray text-xs mt-6">
      By clicking <span v-if="!isFormSend">“Next”</span>
      <span v-else>"Next"</span> I agree to the
      <router-link
        target="_blank"
        :to="{
          name: ROUTER_NAMES.PRIVACY,
          query: { from: ROUTER_NAMES.CREATE_PASSWORD },
        }"
        >“Privacy Policy <br />
        & Terms and Conditions”</router-link
      >
    </p>
  </div>
</template>

<script>
import UiValidationInput from "@/components/ui/UiValidationInput";
import UiButton from "@/components/ui/UiButton";
import { ButtonType } from "@/enums/button";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { ref } from "vue";
import usersApi from "@/services/api/users-api";
import { useRoute } from "vue-router";
import { ROUTER_NAMES } from "@/router/router.enum";
import SocialAuthButtons from "@/modules/auth/components/SocialAuthButtons.vue";

export default {
  components: {
    Form,
    UiButton,
    UiValidationInput,
    SocialAuthButtons,
  },
  setup() {
    const error = ref("");
    const route = useRoute();
    const isFormSend = ref(false);
    const schema = Yup.object().shape({
      password: Yup.string().trim().min(10).required(),
      confirm_password: Yup.string()
        .trim()
        .required()
        .oneOf([Yup.ref("password")], "Passwords do not match"),
    });
    const token = route.query.token;

    const onSubmit = async (values) => {
      try {
        route.name === ROUTER_NAMES.RESET_PASSWORD
          ? await usersApi.completeResetPassword({
              password: values.password,
              token,
            })
          : await usersApi.completeRegistration({
              password: values.password,
              token,
            });
        isFormSend.value = true;
      } catch (err) {
        error.value = err;
      }
    };

    return {
      ROUTER_NAMES,
      isFormSend,
      onSubmit,
      schema,
      error,
      route,
      ButtonType,
    };
  },
};
</script>

<style lang="scss" scoped>
.auth-form {
  @apply w-auth-form;
  @apply flex flex-col items-center;
  @apply mx-auto;
  @apply h-full;

  &__title {
    @apply text-4xl;
    @apply mb-11;
  }

  &__field {
    @apply w-full;
    @apply mb-5;
  }
}
.auth-error {
  @apply absolute top-4;
  @apply text-red text-sm font-semibold;
}
</style>
